@import (reference) "../_variables";

.nav-jumplinks {
	background: #FAF8FC !important;
	margin-bottom: 0px;
	margin-top: -100px;

	.content {
		padding: 0!important;
	}
}

.table-of-contents-bar__list--mobile {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	@media (min-width: @screen-tablet) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		
	}
	@media (min-width: @screen-tablet-max) {
		display: none;
	}
}

.table-of-contents-bar__list--desktop {
	display: none;
	@media (min-width: @screen-laptop) {
		list-style: none;
		display: flex;
		font-size: 16px;
		padding: 16px 0 30px;
		gap: 16px 32px;
		flex-wrap: wrap;
	}
	
	.table-of-contents-bar__list-item-link {
		font-weight: normal;
		color: #000;
	}
}

.table-of-contents-bar__list-header {
	display: none;
	@media (min-width: @screen-laptop) {
		padding-top: 30px;
		display: block;
	}
}

.table-of-contents-bar__list-item--bold {
	font-weight: 700;
}