@import (reference) "../global/components/_simple-text-and-btn-cta.less";
@import (reference) "../global/components/expertContributions.less";
@import (reference) "../mixins/_styleResets.less";
@import (reference) "_variables.less";
@import "./components/_tableOfContentsBar.less";
@import "../global/components/_dial-gauge.less";
@import "../global/components/_plainExam.less";
@import "../global/components/_practice-questions-module.less";


.homepage {
	@import "pages/_home.less";
}

.individual-test {
	.passage {
		background-color: @color-gray-e;
		border-radius: 30px;
	}
	
	#practice-exam-header {
		.font-h2();
	}

	p.pull-quote {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		margin-top: @spacing-standard;

		@media (min-width: @screen-tablet) {
			margin-top: @spacing-large;
		}
	}

	button.start-test {
		margin: 0 auto @spacing-standard;
		display: block;

		@media (min-width: @screen-tablet) {
			margin-bottom: @spacing-large;
		}

		&:not(:first-child) {
			margin-top: @spacing-standard;
		}
	}
	
	.horizontal-line {
		border-top: none;
	}
	
	#exam-instructions .exam-instructions__header {
		.font-h3();
	}
	
	.practice-exam__question {
		.font-body();
		
		&::before {
			color: @color-purple;
		}
	}
	
	.practice-exam__prompt {
		color: @color-purple;
		font-weight: @font-weight-boldest;
		margin-bottom: 20px;
		
		@media (min-width: @screen-tablet) {
			margin-bottom: 30px;
		}
	}
	
	.practice-exam__options {
		font-family: @font-family-roboto;
	}
	
	.practice-test-results-cta {
		margin: @spacing-standard 0;
	}
	
	.practice-test-results-sign-up-button {
		.cta__create-account {
			font-family: @font-family-roboto;
			
			@media (min-width: @screen-laptop) {
				font-size: @font-size-lg;
				font-weight: @font-weight-bold;
			}
		}
	}
	
	.start-test,
	.practice-exam__submit,
	.practice-exam__reset {
		.pill-button(@color-brand: @color-royal);
		font-family: @font-family-roboto;
		min-width: auto;
	}
}

.suite {
	header.hero {
		.hero-action {
			max-width: 500px;

			p {
				text-align: center;
			}
		}

		@media (max-width: @screen-mobile-max) {
			.hero-action {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

.expert-contributions-container {
	.expert-contributions(@color-purple-3, @color-purple-light, @color-purple);
	
	.view-more-state ~ .view-more-trigger:after {
		color: @color-royal !important;
		background-color: @color-purple-light !important;
		border-radius: 50%;
	}
}

main .content {
	&:nth-child(even) {
		background-color: @color-bg-light;
	}
	
	> * {
		max-width: @max-width-content;
		margin-left: auto;
		margin-right: auto;
	}
	
	> .imageplugin {
		width: 100%;
	}
}

header.hero {
	padding: @spacing-standard @spacing-standard 150px;
	
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 20px;
		
		& > h1 {
			font-family: 'Oswald', sans-serif;
			text-align: center;
			
			@media (min-width: @screen-tablet) {
				max-width: 70%;
			}
		}
		
		& > p {
			text-align: center;
			margin-top: 20px;
			
			@media (min-width: @screen-tablet) {
				max-width: 60%;
				margin-top: 30px;
			}
		}
	}
	
	@media (min-width: @screen-tablet) {
		padding: @spacing-standard @spacing-standard 240px;
	}
}

.pill-button, a.pill-button {
	.pill-button(@color-brand: @color-royal);
	
	&:hover {
		background-color: @color-salmon-light;
	}
}

.mixin-text-and-btn-full-width(@max-width: @max-width-content);

.text-and-btn-cta__header {
	font-size: 30px;
	
	@media (min-width: @screen-tablet) {
		font-size: 48px;
	}
}

.text-and-btn-cta__section,
.text-and-btn-cta__btn-container {
	text-align: center;
}

.text-and-btn-cta__section {
	padding-bottom: @spacing-xlarge;
}

.text-and-btn-cta__btn.pill-button {
	font-family: @font-family-roboto;
	margin-top: @spacing-large;
	margin-bottom: 0;
}

.title--no-hero {
	text-align: center;
	padding: @spacing-paragraph @spacing-standard;
}

.error--body {
	text-align: center;
	padding: 200px 0;
	
	@media (min-width: @screen-laptop) {
		padding: 250px 0;
	}
}
