@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

@import "../../global/components/_testimonial.less";

@import "../sections/_bigFeatures.less";
@import "../sections/_hero.less";
@import "../sections/_praxisCore.less";
@import "../sections/_praxisSubject.less";
@import "../sections/_testimonials.less";
@import "../sections/_topFeatures.less";
@import "../sections/_weOffer.less";

.main {
	padding: 0;
}
