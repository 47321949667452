@import (reference) "../_variables";

.praxis-subject-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 20px 80px;
}

.praxis-subject-section__title {
	color: @color-header-black;
	font-size: 50px;
	font-family: @font-family-header;
	font-weight: @font-weight-bold;
	text-align: center;
}

.praxis-subject-table {
	max-width: 1150px;
	margin-top: 10px;
	border-collapse: collapse;
	
	@media (min-width: @screen-tablet) {
		margin-top: 50px;
	}
	
	th {
		padding: 0 0 20px;
		background-color: transparent;
		color: @color-black;
		font-size: 36px;
		font-weight: @font-weight-bold;
		font-family: @font-family-header;
		text-wrap: nowrap;
		text-transform: capitalize;
	}
	
	th:not(:first-child) {
		@media (min-width: @screen-tablet) {
			text-align: center;
		}
	}
	
	tr:not(:first-child) {
		padding: 30px 0;
		border-top: 1px solid @color-gray-border;
	}
	
	tr:nth-child(2) {
		border-top: none;
	}
	
	td {
		padding: 0 20px 20px;
		column-gap: 45px;
		
		@media (min-width: @screen-tablet) {
			padding: 30px 0 30px;
		}
	}
	
	td:before {
		color: @color-black;
		font-size: 20px;
		font-weight: @font-weight-bold;
		font-family: @font-family-header;
	}
	
	td:not(:first-child) {
		@media (min-width: @screen-tablet) {
			padding: 30px 0 60px 30px ;
			text-align: center;
		}
	}
}

.praxis-subject-table__exam {
	color: @color-purple;
	font-size: 20px;
	font-weight: @font-weight-bold;
}

.praxis-subject-table__info {
	color: @color-text-gray;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	line-height: 27px;
}
