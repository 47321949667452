@import (reference) "../global-variables.less";

.text-and-btn-cta__section {
	
	padding-bottom: 30px;
	@media (min-width: @screen-laptop) {
		padding-bottom: 60px;
	}
}

.text-and-btn-cta__section--gray1 {
	background-color: @color-gray-1;
}

.text-and-btn-cta__header {
	text-align: center;
}

.text-and-btn-cta__btn-container {
	text-align: center;
	margin-top: 30px;
	
	@media (min-width: @screen-laptop) {
		margin-top: 60px;
	}
}

.mixin-text-and-btn-full-width(@max-width) {
	.content--full-width .text-and-btn-cta__header {
		max-width: @max-width;
		margin: 0 auto;
	}
}