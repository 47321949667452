@import "../global/global-variables";

@color-header-black: #272829;
@color-text-gray: #61677A;
@color-bg-light: #FAF8FC;

@color-header-dark: #252525;
@color-sea-green: #7FD6C3;
@color-royal: #363B89;
@color-royal-dark: #2b2f6e;
@color-purple: #574785;
@color-black: #000000;
@color-brand: @color-purple;
@color-orchid: #9A7BC1;
@color-brand-light: @color-orchid;
@color-purple-light: #CABADE;
@color-purple-3: #7E659E;
@color-brand-secondary-light: @color-purple-light;
@color-background: #FAF8FC;
@color-salmon: #FE9963;
@color-salmon-light: #FFB087;
@color-salmon-lighter: #FFD7C3;
@color-nav-border: @color-purple-3;

@color-gray-f8: #F8F8F8;
@color-gray-e: #EEEEEE;
@color-gray-g3: #B9B9B9;
@color-gray-a: #AAAAAA;
@color-gray-8: #888888;
@color-gray-7: #777777;
@color-gray-5: #555555;
@color-gray-40: #404040;
@color-gray-black: #282F39;
@color-gray-3: #B9B9B9;
@color-gray-2c: #2C2B2F;
@color-gray-2: #E1E1E1;
@color-gray-g5: #1A1C1E;
@color-gray-border: #D9D9D9;
@color-gray-select: #B6B6B6;
@color-text: @color-text-gray;
@color-link: @color-purple;
@color-link-secondary: #213497;
@color-highlight: @color-salmon;
@color-button: @color-royal;
@color-button-active: @color-sea-green;
@color-practice-exam-success: @color-button;

@color-white: #FFFFFF;

@screen-xs: 375px;
@screen-xs-max: 575px;
@screen-mobile: 576px;
@screen-mobile-max: (768px - 1);
@screen-tablet: 768px;
@screen-tablet-max: 991px;
@screen-laptop: 992px;
@screen-max-content: (@max-width-content + @spacing-standard * 2);
@screen-max-hero: (@max-width-hero + @spacing-standard * 2);
@screen-xl: 1200px;
@screen-desktop: @screen-xl;
@screen-xxl: 1400px;

@spacing-xlarge: 120px;
@spacing-large: 60px;
@spacing-standard: 30px;
@spacing-paragraph: 15px;
@spacing-minimum: 5px;

@border-radius-large: 10px;

@font-import: 'https://fonts.googleapis.com/css2?family=Oswald:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&family=Plus+Jakarta+Sans:wght@600&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Roboto:wght@400;500&display=swap';

@font-family: 'Montserrat', sans-serif;
@font-family-header: 'Oswald', sans-serif;
@font-family-roboto: 'Roboto', sans-serif;

@font-weight-boldest: 700;
@font-weight-bolder: 600;
@font-weight-bold: 500;
@font-weight-semibold: 500; // need this variable for some shared less file definitions
@font-weight: 400;
@font-weight-thin: 300;

@font-size-xs: 13px;
@font-size-sm: 14px;
@font-size: 16px;
@font-size-lg: 18px;
@font-size-xl: 20px;
@font-size-xxl: 25px;
@font-size-xxxl: 30px;

@max-width-content: 1000px;
@max-width-hero: 1200px;

@letter-spacing-ssp: 0.1px;
@line-height-pct: 140%;

.font-body() {
	font-family: @font-family;
	font-weight: @font-weight;
	font-size: @font-size;
	line-height: @line-height-pct;
	color: @color-text;
	
	@media (min-width: @screen-tablet) {
		font-size: @font-size-lg;
	}
}

.font-h3() {
	.font-body();
	color: @color-header-black;
	font-family: @font-family-header;

	font-size: 25px;

	@media (min-width: @screen-tablet) {
		font-size: 24px;
	}
}

.font-h2() {
	.font-h3();
	font-size: 30px;

	@media (min-width: @screen-tablet) {
		font-size: 48px;
	}

	font-weight: @font-weight-bold;
}

.font-h1() {
	.font-h2();

	font-size: 30px;

	@media (min-width: @screen-tablet) {
		font-size: 80px;
		line-height: 90px;
	}
}

.hero-bg() {
	background-color: @color-bg-light;
}

.font-link() {
	color: @color-link;
	font-weight: @font-weight-bold;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

@material-expand-more: '\e5cf';
@material-expand-less: '\e5ce';
@material-arrow-right: '\e5df';
@material-arrow-forward: '\e5c8';
@material-chevron-forward: '\e5e1';
@material-profile: '\e7fd';

@color-error: #A94442;
@color-blue-3: #6A98B1;
@color-gray-1: #F8F8F8;
@color-gray-4: #777777;
