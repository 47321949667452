@import (reference) "../_variables";

.big-features-section {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 80px 30px;
}

.big-features-section__list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;
	padding-inline-start: 0;
	max-width: 1150px;
	
	@media (min-width: @screen-laptop) {
		flex-direction: row;
		justify-content: center;
		align-items: stretch;
		gap: 30px;
		
		> * {
			flex: 1;
		}
	}
}

.big-feature-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
	max-width: 330px;
	background-color: @color-white;
	font-family: @font-family;
	
	@media (min-width: @screen-laptop) {
		justify-content: space-between;
		max-width: 522px;
	}
}

.big-feature-card__details {
	display: flex;
	flex-direction: column;
	gap: 20px;
	text-align: center;
}

.big-feature-card__heading {
	color: @color-header-black;
	font-size: 25px;
	font-weight: @font-weight-boldest;
	font-family: @font-family-header;
}

.big-feature-card__paragraph {
	margin: 0;
	color: fade(@color-text-gray, 85%);
	line-height: 24px;
}
