@import (reference) "../_variables";

.top-features-section {
	display: flex;
	justify-content: center;
	padding: 80px 0;
	
	@media (min-width: @screen-laptop) {
		padding: 120px 0;
	}
}

.top-feature-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	padding-inline-start: 0;
	
	@media (min-width: @screen-laptop) {
		flex-direction: row;
		gap: 40px;
	}
	
	@media (min-width: @screen-desktop) {
		gap: 65px;
	}
}

.feature-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	width: 292px;
	height: 292px;
	background-color: @color-white;
	font-family: 'Roboto', sans-serif;
	border-radius: 10px;
	box-shadow: 0 11px 26px 0 fade(@color-black, 15%);
	overflow: hidden;
}

.feature-card__link {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 30px;
}

.feature-card__description {
	color: @color-header-black;
	text-align: center;
	font-size: 24px;
	font-weight: @font-weight-bold;
}
