@import (reference) "../_variables";

.praxis-core-section {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 30px 120px;
}

.praxis-core-section__container {
	padding: 60px 22px;
	border-radius: 10px;
	background-color: @color-white;
	box-shadow: 0 8px 34px 0 fade(@color-black, 15%);
	max-width: 1150px;
	
	@media (min-width: @screen-laptop) {
		padding: 120px 58px;
	}
}

.praxis-core-section__heading {
	padding: 0 22px;
	color: @color-header-black;
	font-size: 30px;
	font-weight: @font-weight-boldest;
	text-align: center;
	font-family: @font-family-header;
	
	@media (min-width: @screen-laptop) {
		padding: 0;
		font-size: 50px;
	}
}

.praxis-core-section__list {
	display: grid;
	gap: 1px;
	padding: 0;
	margin-top: 30px;
	background-color: @color-gray-border;
	list-style-type: none;
	
	@media (min-width: @screen-laptop) {
		grid: auto/auto-flow;
		margin-top: 60px;
	}
}

.praxis-core-item {
	display: grid;
	gap: 20px;
	padding: 30px 0;
	background-color: @color-white;
	font-family: @font-family;
	text-align: center;
	
	@media (min-width: @screen-laptop) {
		justify-content: space-between;
		justify-items: center;
		gap: 30px;
		padding: 0 30px;
	}
}

.praxis-core-item__title {
	color: @color-header-black;
	font-size: 25px;
	font-weight: @font-weight-boldest;
	font-family: @font-family-header;
	
	@media (min-width: @screen-laptop) {
		max-width: 252px;
		font-size: 36px;
		font-weight: @font-weight-bold;
		line-height: normal;
	}
}

.praxis-core-item__detail {
	color: fade(@color-text-gray, 85%);
	line-height: 24px;
}

.praxis-core-item__link {
	margin: 0 15px;
	padding: 20px;
	background-color: @color-white;
	border: 1px solid @color-royal;
	border-radius: 38px;
	
	@media (min-width: @screen-laptop) {
		align-self: flex-end;
		margin: 0;
	}
}
