@import (reference) "../../mixins/_icons.less";
@import (reference) "../../mixins/_styleResets.less";

main section.testimonials > .content {
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	@media(min-width: @screen-laptop){
		padding-left: 30px;
		padding-right: 30px;
	}
}

.testimonials__header {
	margin-bottom: 50px;
	
	@media(min-width: @screen-laptop){
		margin-bottom: 190px;
	}
}

.testimonials__header--center {
	text-align: center;
}

.testimonials__header--margin-sm {
	margin-bottom: 50px;
}

.testimonial-carousel.swiper {
	padding: 15px;
	@media(min-width: @screen-laptop){
		padding: 15px;
		max-width: 500px;
		margin-left: 0;
	}

	.swiper-button-controls {
		display: flex;
		justify-content: center;
		margin-top: 70px;
		gap: 50px;
		
		@media(min-width: @screen-laptop) {
			justify-content: left;
		}
	}
	.swiper-button-next:after, .swiper-button-prev:after {
		font-size: 18px!important;
	}
}

.testimonial__button {
	color: @color-white;
	background: @color-brand;
	padding: 25px;
	border-radius: 50%;
	position: relative;
}

.testimonial__button--purple {
	background-color: @color-purple;
}

.testimonial__button--teal {
	background-color: @color-teal;
}

.testimonial-carousel .swiper-slide{
	height: auto;
}

.testimonial__container {
	border-radius: 10px;
	background: @color-white;
	padding: 60px 15px 30px;
	box-shadow: 0 0 11px -5px #555;
	border-bottom: 5px solid @color-brand;
}

.testimonial__container--purple {
	border-bottom-color: @color-purple;
}

.testimonial__container--teal {
	border-bottom-color: @color-teal;
}

.testimonial__quote {
	position: relative;
}

.testimonial__quote-icon {
	color: @color-brand;
	
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	
	top: -25px;
	height: 50px;
	width: 50px;
}
.testimonial__quote-icon--purple {
	color: @color-purple;
}
.testimonial__quote-icon--teal {
	color: @color-teal;
}

.testimonial__name {
	font-weight: @font-weight-bold;
	margin-top: 30px;
}

.testimonials__bg {
	
	@media(min-width: @screen-laptop){
		background-image: url("/resources/img/71/student.png");
		background-size: 39rem;
		background-position-x: right;
		background-position-y: bottom;
		background-repeat: no-repeat;
	}
}

@media(min-width: @screen-laptop){
	.testimonials__bg--alt-tablet {
		background-image: url("/resources/img/71/smiley-teacher-holding-tablet.png");
	}
}

//The comp wants the image to be somewhat close to the center but not collide with the text
// we probably need to use an img tag and get creative to solve it in a more generic way
.testimonials__bg--purple-student-notebook {
	@media(min-width: @screen-laptop) {
		background-image: url("/resources/img/75/man-laptop-smiling.png");
		background-size: contain;
		background-position-x: 52vw;
	}
}
.testimonials__bg--teal-nurse-laptop {
	@media(min-width: @screen-laptop) {
		background-image: url("/resources/img/81/nurse-teal-holding-laptop.png");
		background-size: contain;
	}
	
	@media(min-width: 1300px) {
		background-position-x: 85%;
	}
	
	@media(min-width: 1600px) {
		background-position-x: 75%;
	}
}

