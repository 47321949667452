@import (reference) "../_variables";
@import (reference) "../../mixins/_styleResets";

.hero-section {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	position: relative;
	padding: 0;
	background-color: @color-bg-light;
	
	@media (min-width: @screen-laptop) {
		padding: 0 0 0 @spacing-large;
	}
}

.hero-section__background-alt-color {
	display: none;
	position: absolute;
	right: 0;
	background: linear-gradient(343deg, @color-purple 0%, @color-orchid 100%);
	height: 100%;
	width: 25%;
	
	@media (min-width: @screen-laptop) {
		display: block;
	}
}

.hero-section__container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	max-width: 1200px;
	text-align: left;
	
	@media (min-width: @screen-laptop) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-auto-flow: column;
		gap: 40px;
		min-height: 350px;
	}
}

.hero-section__content {
	margin: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 92px 40px 0;
	font-family: @font-family;
	
	@media (min-width: @screen-laptop) {
		padding: 60px 0;
		align-items: start;
		flex-grow: 1;
		flex-shrink: 1;
	}
}

.hero-section__title {
	font-size: 45px;
	font-weight: 600;
	font-family: @font-family-header;
	color: @color-header-black;
	line-height: 55px;
	text-align: center;
	
	@media (min-width: @screen-laptop) {
		text-align: left;
	}
}

.hero-section__copyright {
	font-family: 'Plus Jakarta Sans', sans-serif;
}

.hero-section__action {
	padding-bottom: 15px;
	width: 100%;
}

.hero-section__select {
	padding-top: 25px;
	padding-bottom: 40px;
	font-size: @font-size;
	color: fade(@color-black, 85%);
	text-align: center;
	line-height: 24px;
	
	@media (min-width: @screen-laptop) {
		padding-top: 50px;
		padding-bottom: 60px;
		font-size: 24px;
		text-align: left;
	}
}

.hero-section__links {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	
	@media (min-width: @screen-laptop) {
		flex-direction: row;
	}
}

.hero-section__link {
	padding: 15px 35px;
	font-size: @font-size;
	font-weight: @font-weight-boldest;
	border-radius: 100px;
	border: 1px solid @color-purple;
	transition: 0.2s;
	
	&:hover {
		background-color: @color-brand;
		color: @color-white;
		text-decoration: none;
	}
	
	@media (min-width: @screen-laptop) {
		padding: 20px 60px;
		font-size: @font-size-lg;
	}
}

.hero-section__image {
	display: block;
	z-index: 10;
	margin: 0 auto;
	width: 400px;
	
	@media (min-width: @screen-laptop) {
		width: auto;
		height: 100%;
		max-height: 100%;
		max-width: fit-content;
		margin: 0;
		position: absolute;
		bottom: 0;
		right: 0;
		align-self: flex-end;
	}
}
