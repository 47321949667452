@import (reference) "../_variables";

.testimonials-section-container {
	@media (min-width: @screen-laptop) {
		padding-top: 50px;
		background-color: @color-bg-light;
	}
}

.testimonials-section {
	.testimonial__quote {
		color: @color-text-gray;
		font-family: @font-family;
		
		@media (min-width: @screen-laptop) {
			color: fade(@color-black, 90%);
		}
	}
	
	.testimonial__name {
		color: @color-header-black;
		font-weight: @font-weight-boldest;
		font-family: @font-family;
	}
	
	.testimonial-carousel {
		@media (min-width: @screen-laptop) {
			padding-bottom: 98px;
		}
	}
}

.testimonials-section__header {
	color: @color-header-black;
	font-size: 30px;
	font-weight: @font-weight-boldest;
	font-family: @font-family-header;
}
