@import (reference) "../_variables";

.we-offer-section {
	display: grid;
	grid-template-columns: 1fr;
	
	@media (min-width: @screen-tablet) {
		grid-template-columns: 1fr 1fr;
	}
}

.we-offer-section__image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.we-offer-section__content-container {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: @color-bg-light;
	padding: 40px 20px 80px;
	
	@media (min-width: @screen-tablet) {
		padding: 40px;
	}
	
	@media (min-width: @screen-desktop) {
		padding: 80px 100px;
	}
}

.we-offer-section__content {
	font-family: @font-family;
}

.we-offer-section__heading {
	color: @color-header-black;
	font-size: 30px;
	font-weight: @font-weight-boldest;
	font-family: @font-family-header;
}

.we-offer-section__paragraph {
	color: fade(@color-text-gray, 85%);
	line-height: 24px;
}

.we-offer-section__paragraph--bold {
	font-weight: @font-weight-boldest;
}

.we-offer-section__list {
	padding-inline-start: 25px;
	color: @color-text-gray;
}
